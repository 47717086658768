import { EntriesQueries, EntryQueries, EntrySkeletonType, createClient } from 'contentful';

export type Space = 'preview' | 'misc' | 'misc-preview';

const client = createClient({
  space: 't1oaprbqkyo4',
  accessToken: '95AU06jZZtujhgpMIPQ-PsRQZ1hefx622Qefi7XVuRw',
  host:
    (process.env.NODE_ENV as string) == 'staging' ? 'preview.contentful.com' : 'cdn.contentful.com',
  retryLimit: 10
});

const previewClient = createClient({
  space: 't1oaprbqkyo4',
  accessToken: '0tw3WKav9E4RvVV-YmjSuaM9xNJRaHYirb7HNfKrW1g',
  host: 'preview.contentful.com',
  retryLimit: 10
});

const blogAndMiscClient = createClient({
  space: '6j8y907dne6i',
  accessToken: 'wdeXaUqZVxtTlP1IpAuRaWXyIqsfElGyFZufBLRQTuQ',
  host: 'cdn.contentful.com',
  retryLimit: 10
});

const blogAndMiscClientPreview = createClient({
  space: '6j8y907dne6i',
  accessToken: 'g2mm_43gcfOOcPISzHIJzCA8ArX_5DcV8WLmqGWGvYY',
  host: 'preview.contentful.com',
  retryLimit: 10
});

const CONTENTFUL_SPACE_PREVIEW: Space = 'preview';
const CONTENTFUL_SPACE_MISC: Space = 'misc';
const CONTENTFUL_SPACE_MISC_PREVIEW: Space = 'misc-preview';
const contentfulPreviewMode: boolean = Boolean(process.env.NEXT_PUBLIC_CONTENTFUL_USE_PREVIEW_MODE);

const queries = {
  // Type for the query object will be included on v10
  query: function <T extends EntrySkeletonType>(query: { [k: string]: any }, space?: Space) {
    const queryObject: EntriesQueries<T, any> = {
      include: 10,
      ...query
    };

    if (contentfulPreviewMode) {
      if (space === CONTENTFUL_SPACE_MISC) {
        return blogAndMiscClientPreview.getEntries<T>(queryObject);
      }

      return previewClient.getEntries<T>(queryObject);
    }

    if (space == CONTENTFUL_SPACE_PREVIEW) {
      query.order = '-sys.createdAt';
      return previewClient.getEntries<T>(queryObject);
    } else if (space == CONTENTFUL_SPACE_MISC) {
      return blogAndMiscClient.getEntries<T>(queryObject);
    } else if (space === CONTENTFUL_SPACE_MISC_PREVIEW) {
      return blogAndMiscClientPreview.getEntries<T>(queryObject);
    } else {
      return client.getEntries<T>(queryObject);
    }
  },
  getEntry: function <T extends EntrySkeletonType>(
    id: string,
    query: { [k: string]: any },
    space?: Space
  ) {
    const queryObj: EntryQueries<any> = {
      include: 10,
      ...query
    };

    if (space === CONTENTFUL_SPACE_MISC) {
      if (contentfulPreviewMode) {
        return blogAndMiscClientPreview.getEntry<T>(id, queryObj);
      }

      return blogAndMiscClient.getEntry<T>(id, queryObj);
    }

    if (contentfulPreviewMode) {
      return previewClient.getEntry<T>(id, queryObj);
    }

    return client.getEntry<T>(id, queryObj);
  }
};

export default queries;
