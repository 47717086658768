'use client';
import { FC, useEffect, useState, createContext, Context } from 'react';
import contentful from '@ui/FSAStore/utils/contentful';

type LivelySettingsValueFetching = {
  isFetching: true;
  fetchError: false;
  settings: null;
};

type LivelySettingsValueSuccess = {
  isFetching: false;
  fetchError: false;
  settings: LivelyContentful.BlogAndMisc.ProcessedEntries.LivelySettings;
};

type LivelySettingsValueError = {
  isFetching: false;
  fetchError: true;
  settings: null;
};

export type LivelySettingsValue =
  | LivelySettingsValueSuccess
  | LivelySettingsValueFetching
  | LivelySettingsValueError;

type LivelySettingsProviderProps = {
  children: React.ReactNode;
};

const initialValue: LivelySettingsValueFetching = {
  isFetching: true,
  fetchError: false,
  settings: null
};

export const LivelySettingsContext: Context<LivelySettingsValue> =
  createContext<LivelySettingsValue>(initialValue);

const LivelySettingsProvider: FC<LivelySettingsProviderProps> = ({ children }) => {
  const [value, setValue] = useState<LivelySettingsValue>(initialValue);

  useEffect(() => {
    const updateValue = async () => {
      let newValue: LivelySettingsValue;

      try {
        const settings: LivelyContentful.BlogAndMisc.RawEntries.LivelySettings = await contentful
          .query<LivelyContentful.BlogAndMisc.Fields.LivelySettings>(
            {
              content_type: 'livelySettings'
            },
            'misc'
          )
          .then((settings) => settings.items[0]);

        newValue = {
          isFetching: false,
          fetchError: false,
          settings: {
            ...settings.fields,
            cookieTypes: settings.fields.cookieTypes?.map((type) => type.fields) || []
          }
        };
      } catch (e) {
        console.error('Lively settings fetch error', e);

        newValue = {
          isFetching: false,
          fetchError: true,
          settings: null
        };
      }

      setValue(newValue);
    };

    updateValue();
  }, []);

  return <LivelySettingsContext.Provider value={value}>{children}</LivelySettingsContext.Provider>;
};

export default LivelySettingsProvider;
